import Keycloak from "keycloak-js";
import Cookies from 'js-cookie'
import * as Sentry from "@sentry/browser";

window.fwKeycloak = {
    access_token_cookie: 'creator_access_token',
    access_refresh_cookie: 'creator_refresh_token',

    createKeycloak: function (url, realm, clientId) {
        this.keycloak = new Keycloak({
            url: url,
            realm: realm,
            clientId: clientId
        })
        return Promise.resolve()
    },

    initKeycloak: function (redirectUri) {
        return this.keycloak.init({
            onLoad: 'login-required',
            pkceMethod: 'S256',
            checkLoginIframe: false,
            redirectUri: redirectUri,
            scope: 'offline_access'
        }).catch(this.handleError)
    },

    handlePostLogin: function(redirectTo, membershipsDisabledRole, redirectToIfDisabled, authenticated) {
        if (authenticated) {
            console.debug("User authenticated")
            this.setAccessTokenCookie()
            if (this.keycloak.realmAccess.roles.includes(membershipsDisabledRole)) {
                window.location = redirectToIfDisabled
            } else {
                window.location = redirectTo
            }
        } else {
            console.debug("User not authenticated")
            return Promise.resolve()
        }
    },

    setAccessTokenCookie: function () {
        console.debug("Saved token in cookies")

        const tokenAttributes = {sameSite: 'strict', secure: this.isHttps()}

        Cookies.set(this.access_token_cookie, this.keycloak.token, tokenAttributes)
        Cookies.set(this.access_refresh_cookie, this.keycloak.refreshToken, tokenAttributes)
    },

    isHttps: function () {
        return location.protocol === 'https:'
    },

    logout: function (redirectTo) {
        console.debug("User logout")
        this.keycloak.logout({
            redirectUri: redirectTo
        }).catch(this.handleError)
    },

    handleError: function (error) {
        Sentry.captureException(error)
        console.error(error)
    }
}
